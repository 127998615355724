/* eslint-disable @next/next/no-sync-scripts */

interface CookieScriptProps {
  cookieKey?: string | null;
}

export function CookieScript({ cookieKey }: CookieScriptProps) {
  if (!cookieKey) return null;
  return (
    <>
      <script
        src={`//cdn.cookie-script.com/s/${cookieKey}.js`}
        charSet="UTF-8"
      />
    </>
  );
}
