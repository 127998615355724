import { useCallback, useEffect, useState } from 'react';

function useDarkMode(handler?: () => void) {
  const [enabled, setEnabled] = useState(false);
  const onChange = useCallback(() => {
    setEnabled(
      window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches,
    );

    if (!handler) {
      return;
    }

    handler();
  }, [handler]);

  useEffect(() => {
    setEnabled(
      window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches,
    );

    const media =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

    media.addEventListener('change', onChange);
    return () => media.removeEventListener('change', onChange);
  }, [onChange]);

  return enabled;
}

export default useDarkMode;
