import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { appWithTranslation } from 'next-i18next';
import { SWRConfig } from 'swr';

import Meta from 'components/Meta';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

import 'styles/common.scss';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

async function swrFetcher(path: string) {
  const res = await fetch(path);
  return res.json();
}

function App({ Component, pageProps }: AppProps) {
  useGoogleAnalytics();

  return (
    <SWRConfig
      value={{
        fetcher: swrFetcher,
      }}
    >
      <Meta />
      <Component {...pageProps} />
    </SWRConfig>
  );
}

export default appWithTranslation(App);
