import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { pageView } from 'utils/ga';

function useGoogleAnalytics() {
  const router = useRouter();

  useEffect(() => {
    function handleRouteChange(url: string) {
      pageView(url);
    }

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events]);
}

export default useGoogleAnalytics;
