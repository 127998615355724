/* eslint-disable @next/next/no-css-tags */
import Head from 'next/head';

import useDarkMode from 'hooks/useDarkMode';

import { CookieScript } from './CookieScript';

export default function Meta() {
  const darkMode = useDarkMode();
  return (
    <Head>
      <CookieScript cookieKey={process.env.NEXT_PUBLIC_COOKIE_KEY} />
      <link rel="stylesheet" type="text/css" href="/nprogress.css" />

      <meta name="keywords" content="decin, idecin, decinjebozi" />
      <meta name="author" content="forCandies (http://forcandies.com)" />
      <meta name="copyright" content="Statutární město Děčín" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta
        name="msapplication-TileColor"
        content={darkMode ? '#292929' : '#ffffff'}
      />
      <meta name="theme-color" content={darkMode ? '#292929' : '#ffffff'} />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0"
      />
    </Head>
  );
}
